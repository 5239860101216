export enum ConsentType {
    TERMS_AND_CONDITIONS = 0,
    PERSONAL_DATA_PROCESSING = 1,
    SERVICE_WEB_NOTIFICATIONS = 2,
    SERVICE_EMAIL_NOTIFICATIONS = 3,
    SERVICE_PUSH_NOTIFICATIONS = 4,

    MARKETING_NEWSLETTER_MARKETING = 5,
    MARKETING_NEWSLETTER_OBCHODNI_DOVEDNOSTI = 6,
    MARKETING_NEWSLETTER_MANAGEMENT_AND_LEADERSHIP = 7,
    MARKETING_NEWSLETTER_KURZY_PRO_ASISTENTKY = 8,
    MARKETING_NEWSLETTER_RIZENI_NAKUPU = 9,
    MARKETING_NEWSLETTER_PERSONALISTIKA = 10,
    MARKETING_NEWSLETTER_MZDOVE_UCETNICTVI = 11,
    MARKETING_NEWSLETTER_FINANCNI_UCETNICTVI = 12,
    MARKETING_NEWSLETTER_ALL_AREAS = 13,
    MARKETING_NEWSLETTER_GENERAL = 14,
    MARKETING_NEWSLETTER_GOVERNMENT = 15,
}

import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Icons } from '@components/Icon';
import { CheckboxLabel } from '@components/Register/Register.elements';
import useTranslations from '@hooks/useTranslation';
import CheckboxTemplate from '@ui/Checkbox/Checkbox';
import {EMAIL_REGEX, FBQ_EVENTS, ROUTES} from '../../../constants';
import { ConsentType } from '../../../interfaces/Consent';
import { authApi } from '../../../services/auth.service';
import { openModalType } from '../../../store/ui';
import ButtonTemplate from '../../../ui/Button/Button';
import {
    Agreement,
    Area,
    Areas,
    CloseButton,
    Error,
    Input,
    Label,
    SuccessIcon,
    SuccessMessage,
    Title,
    Wrapper,
} from './NewsletterModal.elements';

export interface NewsletterProps {}

const NewsletterModalTemplate: FC<NewsletterProps> = () => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const router = useRouter();

    const NOTIFICATIONS = [
        { label: t('notifications.marketing'), key: ConsentType.MARKETING_NEWSLETTER_MARKETING, isChecked: false },
        { label: t('notifications.sell'), key: ConsentType.MARKETING_NEWSLETTER_OBCHODNI_DOVEDNOSTI, isChecked: false },
        {
            label: t('notifications.management'),
            key: ConsentType.MARKETING_NEWSLETTER_MANAGEMENT_AND_LEADERSHIP,
            isChecked: false,
        },
        {
            label: t('notifications.courses'),
            key: ConsentType.MARKETING_NEWSLETTER_KURZY_PRO_ASISTENTKY,
            isChecked: false,
        },
        { label: t('notifications.buy'), key: ConsentType.MARKETING_NEWSLETTER_RIZENI_NAKUPU, isChecked: false },
        {
            label: t('notifications.wages'),
            key: ConsentType.MARKETING_NEWSLETTER_MZDOVE_UCETNICTVI,
            isChecked: false,
        },
        {
            label: t('notifications.finances'),
            key: ConsentType.MARKETING_NEWSLETTER_FINANCNI_UCETNICTVI,
            isChecked: false,
        },
        {
            label: t('notifications.allAreas'),
            key: ConsentType.MARKETING_NEWSLETTER_ALL_AREAS,
            isChecked: false,
        },
        {
            label: t('notifications.government'),
            key: ConsentType.MARKETING_NEWSLETTER_GOVERNMENT,
            isChecked: false,
        },
    ];

    const emailInputRef = useRef(null);

    const [hasEmailError, setHasEmailError] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    const handleSubmit = async () => {
        setHasEmailError(false);

        if (emailInputRef?.current?.value === '' || !EMAIL_REGEX.test(emailInputRef?.current?.value)) {
            setHasEmailError(true);

            return;
        }

        setIsSending(true);

        await authApi.updateNotificationsModal(
            [{ type: selectedNotification.key, active: selectedNotification.isChecked }],
            emailInputRef?.current?.value
        );

        (window as any)?.fbq?.('track', FBQ_EVENTS.COMPLETE_REGISTRATION);

        setIsSending(false);

        setIsSent(true);
    };

    return (
        <Wrapper center={isSent}>
            {isSent ? (
                <>
                    <SuccessIcon name={Icons.Success} />
                    <SuccessMessage>{t('modal.newsletter.sent')}</SuccessMessage>
                    <CloseButton onClick={() => dispatch(openModalType(null))}>&#10005;</CloseButton>
                </>
            ) : (
                <>
                    <Title dangerouslySetInnerHTML={{ __html: t('modal.newsletter.title') }} />
                    <CloseButton onClick={() => dispatch(openModalType(null))}>&#10005;</CloseButton>
                    <Input
                        ref={emailInputRef}
                        className={hasEmailError ? 'error' : ''}
                        placeholder={t('modal.login.mail.placeholder')}
                    />
                    {hasEmailError && <Error>{t('modal.login.mail.error')}</Error>}
                    <Label>{t('modal.newsletter.label')}</Label>
                    <Areas>
                        <Areas>
                            {NOTIFICATIONS.map((notification) => (
                                <Area key={notification.key}>
                                    <CheckboxTemplate
                                        checked={selectedNotification?.key === notification.key}
                                        onChange={() =>
                                            setSelectedNotification(
                                                selectedNotification?.key === notification.key
                                                    ? null
                                                    : {
                                                          key: notification.key,
                                                          isChecked: true,
                                                          label: notification.label,
                                                      }
                                            )
                                        }
                                        name={notification.label}
                                        label={notification.label}
                                    />
                                </Area>
                            ))}
                        </Areas>
                    </Areas>
                    <Agreement>
                        Souhlasím s{' '}
                        <a
                            onClick={() => {
                                dispatch(openModalType(null));
                                router.push({ pathname: ROUTES.MARKETING });
                            }}
                        >
                            doručováním marketingové komunikace
                        </a>
                    </Agreement>
                    <ButtonTemplate
                        disabled={!selectedNotification}
                        marginTop={40}
                        isLoading={isSending}
                        onClick={handleSubmit}
                        text={t('modal.newsletter.button')}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default NewsletterModalTemplate;
